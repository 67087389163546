import React from "react";
import OwaisZ from "../img/team_pics/Owais.png";
import Rashid from "../img/team_pics/Rashid.png";
import Raza from "../img/team_pics/Raza.png";
import Aneek from "../img/placeholder-image.png";
import Line from "./Line";
import { StaticImage } from "gatsby-plugin-image";

function BoardMembers({ getTranslatedText }) {
  const boardMembersData = [
    {
      imgSrc: OwaisZ,
      title: getTranslatedText("BoardMember Owais"),
      designation: getTranslatedText("BoardMember OwaisDesg"),
      linkedinLink: "https://www.linkedin.com/in/owaiszaidi/",
    },
    {
      imgSrc: Rashid,
      title: getTranslatedText("BoardMember Rashid"),
      designation: getTranslatedText("BoardMember RashidDesg"),
      linkedinLink: "https://www.linkedin.com/in/rashid-maqsood-hamidi-23b3991a/",
    },
    {
      imgSrc: Raza,
      title: getTranslatedText("BoardMember Raza"),
      designation: getTranslatedText("BoardMember RazaDesg"),
      linkedinLink: "https://www.linkedin.com/in/raza-chinoy-69995724/",
    },
    {
      imgSrc: Aneek,
      title: getTranslatedText("BoardMember Aneek"),
      designation: getTranslatedText("BoardMember AneekDesg"),
      linkedinLink: "https://www.linkedin.com/in/aneek-saleh-mohammad-4b2607171/",
    },
  ];

  return (
    <div className="container members">
      <div className="heading-wrapper">
        <Line type="large" />
        <h2 className="our-team__heading">
          {getTranslatedText("BoardMember Heading")}
        </h2>
      </div>
      
      {boardMembersData.map((data) => (
        <div class="row ">
          <div className="wrapper">
            <div className="col-lg-3">
              <div class="img">
                <img src={data.imgSrc} class="image-cover" />
              </div>
            </div>
            <div className="content col-lg-9">
              <h2>{data.title}</h2>
              <p>{data.designation}</p>
              <a
                className="linkedin-icon"
                href={data.linkedinLink}
                target="_blank"
              >
                <StaticImage
                  className="bod-team-card__icon"
                  src="../img/linkedin-icon.svg"
                  alt="Icon"
                  placeholder="blurred"
                />
              <span style={{color:"#007290"}}>{getTranslatedText("BoardMember Link")}</span>  
              </a>
            </div>
          </div>
          <hr className="seperator"></hr>
        </div>
      ))}
    </div>
  );
}

export default BoardMembers;
